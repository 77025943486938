<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="productImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="productImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>
     <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="productImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
      <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="productImg4" :alt="altText" class="imgItem4" />
        <div class="textOverlay1">
          <span class="span1"  @click="gotoFirst">Sobre la empresa</span>
          <span class="span2" @click="gotoAbout">Acerca de los productos</span>
        </div>
        <br>
          <div class="textOverlay2">
          <span class="span3" @click="gotoShow">CONDUSEF</span>
          <span class="span4" @click="gotoDetail">CNBV</span>
        </div>
        <br>
          <div class="textOverlay3">
          <span class="span5" @click="scrollToTop">Acuerdo del Usuario</span>
          <span class="span6" @click="gotoPriva">Política de privacidad</span>
        </div>
      </div>
    </div>
      <div class="imgBox5 min-w-1400">
      <div class="img5 max-w-1400">
        <img :src="productImg5" :alt="altText" class="imgItem5" />
      </div>
    </div>
 
  </div>
</template>

<script>
import productImg1 from "@/page/cherreypréstamos2/components/img/4_1.png";
import productImg2 from "@/page/cherreypréstamos2/components/img/5_2.png";
import productImg3 from "@/page/cherreypréstamos2/components/img/1_6.png";
import productImg4 from "@/page/cherreypréstamos2/components/img/1_7.png";
import productImg5 from "@/page/cherreypréstamos2/components/img/2_5.png";
export default {
  data() {
    return {
      productImg1: productImg1,
      productImg2: productImg2,
productImg3: productImg3,
productImg4: productImg4,
productImg5: productImg5,
      altText: "图片",
    };
  },
       methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    gotoFirst(){
    this.$router.push('/');
     window.scrollTo({ top: 0, behavior: "smooth" });

    },
      gotoAbout(){
    this.$router.push('/service');
     window.scrollTo({ top: 0, behavior: "smooth" });

    },
         gotoShow(){
     
  window.open('https://webapps.condusef.govb.mx/SIPRES/jsp/home_publico.jsp/?idins=17752197144642097000', '_blank');

    },
         gotoDetail(){
     
  window.open('https://websitipld.cnbv.govb.mx/72jd93uf20je89k0j09f012eu8fd90r82r.pdf', '_blank');

    },
        gotoPriva(){
    this.$router.push('/protocol');
     window.scrollTo({ top: 0, behavior: "smooth" });

    },
    
  },

};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: "";
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3850px;
  background-color: #f9f9f9 ;
  border-radius: 32px;
  margin-top: -130px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}



.imgBox3,
.imgBox4,
.imgBox5
{
  display: flex;
  justify-content: center;
  width: 100%;
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}


.img3,.img4,.img5{
  margin-left: 45px;
  margin-right: 45px;
}


.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem4 {
  max-width: 100%;
  margin-top: 100px;
    margin-left: 600px;

  margin-bottom: 100px;
}
.imgItem5 {
  max-width: 100%;
  margin-bottom: 70px;
}
.textOverlay1, .textOverlay2,.textOverlay3{
  position: absolute; 
    text-align: left; 
      cursor: pointer; 


 
}

.textOverlay1{
  margin-top: -400px;
}
.textOverlay2{
  margin-top: -360px;
}
.textOverlay3{
  margin-top: -330px;
}
span{
  font-size: 25px;
  color: #000000;
  font-family: Times New Roman-Bold;
font-weight: bold; /* 将文字加粗 */
margin-top: 50px;
}
.span2{
  margin-left: 90px;
}
.span4{
  padding-left: 165px;
}
.span6{
  padding-left: 50px;
}

 span:hover
{
  color: blue; 
}
</style>
