<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>

    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="importedImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="importedImg4" :alt="altText" class="imgItem4" />
      </div>
    </div>
     <div class="imgBox5 min-w-1400">
      <div class="img5 max-w-1400">
        <img :src="importedImg5" :alt="altText" class="imgItem5" />
      </div>
    </div>
     <div class="imgBox6 min-w-1400">
      <div class="img6 max-w-1400">
        <img :src="importedImg6" :alt="altText" class="imgItem6" />
      </div>
    </div>
    <div class="imgBox7 min-w-1400">
      <div class="img7 max-w-1400">
        <img :src="importedImg7" :alt="altText" class="imgItem7" />
        <div class="textOverlay1">
          <span class="span1" @click="scrollToTop">Sobre la empresa</span>
          <span class="span2" @click="gotoAbout">Acerca de los productos</span>
        </div>
        <br>
           <div class="textOverlay2">
          <span class="span3" @click="gotoShow">CONDUSEF</span>
          <span class="span4" @click="gotoDetail">CNBV</span>
        </div>
        <br>
          <div class="textOverlay3">
          <span class="span5" @click="gotoUser">Acuerdo del Usuario</span>
          <span class="span6" @click="gotoPriva">Política de privacidad</span>
        </div>
      </div>
    </div>
     <!-- <div class="imgBox8 min-w-1400">
      <div class="img8 max-w-1400">
        <img :src="importedImg8" :alt="altText" class="imgItem8" />
      </div>
    </div> -->


  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/cherreypréstamos2/components/img/1_1.png";
import importedImg2 from "@/page/cherreypréstamos2/components/img/1_2.png";
import importedImg3 from "@/page/cherreypréstamos2/components/img/1_3.png";
import importedImg4 from "@/page/cherreypréstamos2/components/img/1_4.png";
import importedImg5 from "@/page/cherreypréstamos2/components/img/1_5.png";
import importedImg6 from "@/page/cherreypréstamos2/components/img/1_6.png";
import importedImg7 from "@/page/cherreypréstamos2/components/img/1_7.png";
export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      importedImg7: importedImg7,
     
      altText: "图片",
    };
  },
   methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
       gotoAbout(){
    this.$router.push('/service');
      window.scrollTo({ top: 0, behavior: "smooth" });

    },
       gotoShow(){
     
  window.open('https://webapps.condusef.govb.mx/SIPRES/jsp/home_publico.jsp/?idins=17752197144642097000', '_blank');

    },
         gotoDetail(){
     
  window.open('https://websitipld.cnbv.govb.mx/72jd93uf20je89k0j09f012eu8fd90r82r.pdf', '_blank');

    },

        gotoPriva(){
    this.$router.push('/protocol');
      window.scrollTo({ top: 0, behavior: "smooth" });

    },
         gotoUser(){
    this.$router.push('/user');
      window.scrollTo({ top: 0, behavior: "smooth" });

    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
/* .imgBox1,
.imgBox2 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
} */
.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,.imgBox6,.imgBox7
{
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  display: block;
}
/* .img1,
.img2 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
} */
.img1,.img2,
.img3,.img4,.img5,.img6,.img7{
  margin-left: 45px;
  margin-right: 45px;
}
.imgItem1,.imgItem2 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem2{
  margin-top: 100px;
}
.imgItem3 {
  max-width: 100%;
  margin-bottom: 100px;
}
.imgItem4 {
  max-width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}
.imgItem5 {
  max-width: 100%;
  margin-bottom: 100px;
}
.imgItem6 {
  max-width: 100%;
  margin-bottom: 100px;
}
.imgItem7 {
  max-width: 100%;
  margin-bottom: 100px;
  margin-left: 600px;
}
/* .imgItem3 {
  max-width: 100%;
  margin-bottom: 100px;
}
.imgItem4 {
margin-bottom: 100px;
} */

.imgItem7 {
  max-width: 100%;
  margin-bottom: 100px;
  position: relative; /* 让包含文字的元素相对于此图片定位 */
}

.textOverlay1, .textOverlay2,.textOverlay3{
  position: absolute; /* 使用绝对定位，相对于包含图片的相对定位的 .imgItem7 元素 */
    text-align: left; /* 设置文本左对齐 */
      cursor: pointer; /* 鼠标放上去变成手指 */


 
}
.textOverlay1{
  margin-top: -430px;
}
.textOverlay2{
  margin-top: -380px;
}
.textOverlay3{
  margin-top: -350px;
}
span{
  font-size: 25px;
  color: #000000;
  font-family: Times New Roman-Bold;
font-weight: bold; /* 将文字加粗 */
margin-top: 50px;
}
.span2{
  margin-left: 90px;
}
.span4{
  padding-left: 165px;
}
.span6{
  padding-left: 50px;
}
 span:hover
{
  color: blue; 
}
</style>
